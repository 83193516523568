<template>
  <div>
    <b-row>
      <b-col>
        <h1 class="font-weight-bolder">
          {{ $t('newIncome') }}
        </h1>
      </b-col>
    </b-row>
    <b-card
      no-body
      class=""
    >
      <validation-observer ref="projectValidation">
        <b-row>
          <b-col>
            <b-card-body>
              <b-form @submit.prevent="validateForm()">
                <b-row>
                  <b-col
                    sm="12"
                    lg="4"
                  >
                    <b-form-group :label="$t('title')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('title')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="income.title"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="4"
                  >
                    <b-form-group :label="$t('folio')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('folio')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="income.folio"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="4"
                  >
                    <b-form-group :label="$t('date')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('date')"
                        rules="required"
                      >
                        <b-form-datepicker
                          v-model="income.income_date"
                          :date-format-options="{
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          size="lg"
                          class="font-weight-bolder"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group :label="$t('description')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('description')"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="income.description"
                          no-resize
                          size="lg"
                          class="font-weight-bolder"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="4"
                  >
                    <b-form-group :label="$t('subtotal')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('subtotal')"
                        rules="required"
                      >
                        <b-input-group
                          prepend="$"
                          size="lg"
                        >
                          <money
                            v-model="income.subtotal"
                            v-bind="money"
                            class="font-weight-bolder px-1 flex-fill"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="4"
                  >
                    <b-form-group :label="$t('iva')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('iva')"
                        rules="required"
                      >
                        <b-input-group append="%">
                          <b-form-input
                            v-model="income.iva"
                            size="lg"
                            class="font-weight-bolder"
                            autocomplete="off"
                            type="number"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="4"
                  >
                    <b-form-group :label="$t('amount-2')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('amount-2')"
                        rules="required"
                      >
                        <b-input-group
                          prepend="$"
                          size="lg"
                        >
                          <money
                            :value="totalAmount"
                            v-bind="money"
                            class="font-weight-bolder px-1 flex-fill"
                            disabled
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="d-flex justify-content-end">
                    <b-button
                      variant="primary"
                      size="lg"
                      class="px-5"
                      type="submit"
                    >
                      {{ $t('save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      income: {
        title: '',
        iva: 16,
      },
      required,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  computed: {
    totalAmount() {
      return (
        +this.income.subtotal
          + this.income.subtotal * (+this.income.iva / 100) || ''
      )
    },
  },
  methods: {
    ...mapActions('incomes', ['addIncome']),
    validateForm() {
      this.$refs.projectValidation.validate().then(success => {
        if (success) {
          this.income.project_id = this.$route.params.projectId
          this.income.total_amount = this.totalAmount
          // quantity is not null in backend
          this.addIncome({
            ...this.income,
            quantity: 0,
          }).then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('incomeAdded'),
                icon: 'SendIcon',
                variant: 'success',
              },
            })
            this.$router.push({
              name: 'incomes',
              params: { projectId: response.project_id },
            })
          })
        }
      })
    },
  },
}
</script>

<style></style>
