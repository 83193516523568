var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('newIncome'))+" ")])])],1),_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"projectValidation"},[_c('b-row',[_c('b-col',[_c('b-card-body',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('title')}},[_c('validation-provider',{attrs:{"name":_vm.$t('title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(_vm.income.title),callback:function ($$v) {_vm.$set(_vm.income, "title", $$v)},expression:"income.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('folio')}},[_c('validation-provider',{attrs:{"name":_vm.$t('folio'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(_vm.income.folio),callback:function ($$v) {_vm.$set(_vm.income, "folio", $$v)},expression:"income.folio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('date')}},[_c('validation-provider',{attrs:{"name":_vm.$t('date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"font-weight-bolder",attrs:{"date-format-options":{
                          year: '2-digit',
                          month: '2-digit',
                          day: '2-digit',
                        },"size":"lg"},model:{value:(_vm.income.income_date),callback:function ($$v) {_vm.$set(_vm.income, "income_date", $$v)},expression:"income.income_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('description')}},[_c('validation-provider',{attrs:{"name":_vm.$t('description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"font-weight-bolder",attrs:{"no-resize":"","size":"lg"},model:{value:(_vm.income.description),callback:function ($$v) {_vm.$set(_vm.income, "description", $$v)},expression:"income.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('subtotal')}},[_c('validation-provider',{attrs:{"name":_vm.$t('subtotal'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$","size":"lg"}},[_c('money',_vm._b({staticClass:"font-weight-bolder px-1 flex-fill",model:{value:(_vm.income.subtotal),callback:function ($$v) {_vm.$set(_vm.income, "subtotal", $$v)},expression:"income.subtotal"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('iva')}},[_c('validation-provider',{attrs:{"name":_vm.$t('iva'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"number"},model:{value:(_vm.income.iva),callback:function ($$v) {_vm.$set(_vm.income, "iva", $$v)},expression:"income.iva"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('amount-2')}},[_c('validation-provider',{attrs:{"name":_vm.$t('amount-2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$","size":"lg"}},[_c('money',_vm._b({staticClass:"font-weight-bolder px-1 flex-fill",attrs:{"value":_vm.totalAmount,"disabled":""}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"px-5",attrs:{"variant":"primary","size":"lg","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }